.service-main-container {
    margin-bottom: 20px;
}

.s-space {
    margin-right: 10px;
}



.service-btn {
    border: none;
    color: white;
    padding: 2px 12px;
    font-size: 12px;
    float: right;
    height: 32px;
}

.btn-edite {
    margin-left: 82px;
}

.rm-paadding {
    padding: 0px !important;
}

.modal-body-adjust {
    height: 384px !important;
    overflow-y: auto;
}