.ol-main-header {
    width: 100%;
    margin-top: 50px;
    margin-left : 5px;
    margin-right : 5px;
    overflow: hidden

}
.btn-add-order {
    margin-top: 35px;
    margin-left: 700px
}
.ol-main-card {
    margin-left: 5px;
    margin-right: 15px;
    margin-top: 5px;
    width: 100%;
    height: 500px;

    overflow: hidden;
}
.testresult-icon {
    margin-top: -10px; 
    margin-left: 15px;
    height: 45px;
}
.ol-input-search {
    background: white !important;
    width: 25px
    

}
.search-btn{
    margin-left: 45px;
    width: 250px

}

#orderTable{
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 12px;
    color: gray;
    overflow: scroll;
  }

  #orderTable tr {
    border-bottom: 1px solid #ddd;
  }

  #orderTable td {
    text-align: left;
    padding: 8px;
  }