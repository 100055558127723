@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
.main-event {
    width: 100%;
    height: 100%;
    background: #DCDCDC;
    margin-top: 100px;
    position: relative;
    margin-bottom: -10px
}

.el-margin {
    margin-top: 8px;
}

.hover-zoom :hover {
    /* transform: scale(1.8); */
    cursor: pointer;
}

.heading {
    margin-left: 40px;
    margin-top: 20px
}

.select-dropdown {
    width: 150px;
    margin-left: 5px
}

#event-maincard {
    max-height: 490px;
    height: 450px;
    overflow-y: auto;
    margin-bottom: 15px;
    margin-top: 10px;
    font-weight: 200px
}

.event-maincard-header {
    width: 100%;
    margin-right: 60px;
}

.tags {
    margin-left: 6px;
}

.tags-date {
    margin-left: -9px;
}

.date-picker-events {
    width: 150px;
    margin-left: 5px
}

.btn-el-gobal {
    width: 120px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    right: 0;
}

.delete-icon-eventlist {
    margin-top: -16px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    right: 0;
    margin-right: 14px;
    cursor: pointer;
}

.btn-back-el-gobal {
    height: 30px;
    font-size: 12px;
    margin-top: -10px;
    float: right;
    background: rgb(57, 154, 211);
    color: white;
    margin-right: 25px;
}
.btn_custom_e{
    width: 130px !important;
    margin-top: 30px !important;
    white-space: nowrap;
    overflow: hidden;
    height: 38px; 
    margin-left: 24px;
}
.btn_custom_e_add{
    width: 130px !important;
    margin-top: 30px !important;
    white-space: nowrap;
    overflow: hidden;
    height: 38px; 
    float: right;
    margin-right: 14px;
}

.btn-custom {
    width: 130px !important;
    margin-top: 24px !important;
    margin-bottom: -2px !important;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    right: 0;
    /* padding: 11px; */
    margin-right: 14px !important;
    height: 38px;
}

#left {
    margin-left: -20px
}

.block-list-events {
    /* padding: 20px 0; */
    max-width: 100%;
    max-height: 100%;
    height: 100px;
    background-color: #fff;
    border-bottom: 2px solid #cccccc
}

.react-confirm-alert-body {
    box-shadow: 2px 3px 17px 9px #007bffa3 !important;
    color: #000000;
}

.react-confirm-alert-body>h1 {
    font-size: 24px;
}

.block-list-events {
    cursor: pointer;
}

.list-content-events {
    cursor: pointer;
    margin-left: 100px;
    color: black
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(57, 154, 211);
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(57, 154, 211);
}

.sizing {
    width: 100%;
}

.list-p {
    font-size: '1.5ex';
    margin: 'auto';
    color: 'gray';
    margin-left: '144px'
}