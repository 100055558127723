:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

.rotate {
  /* animation: rotation 8s infinite linear; */
  transform: rotateY(40deg);
  animation: rotate 5s infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(360deg);
  }
}

body {
  background: #DCDCDC;
  ;
}

.disabled_btn {
  background-color: grey !important;
}

.login-container {
  /* background: linear-gradient(to right, #33AEFF, #33AEFF); */
  background: var(--theme-background-color);
  height: 624px;
}

.main-card {
  margin-left: 550px;
  width: 250px;
  height: 624px;
}

.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  margin-top: 20px;
  font-weight: 300;
  font-size: 1.5rem;
  height: 50px;
}

.page-title {
  color: white;
  margin-left: 5px
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group>input, .form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

/* Fallback for Edge
  -------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group>label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

.login-form {
  margin-top: 15px
}

.login-heading {
  margin-left: 200px
}

/*
for ipad and notebook
*/

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .main-card {
    margin-left: 400px;
    width: 250px;
    height: 624px;
  }
}

.error_spn {
  color: #ff0000;
  margin-left: 60px;
  justify-content: center;
}

.extra-margin {
  margin-top: 10px;
}