#workflowSidenav a {
    position: absolute;
    left: -50px;  
    transition: 0.3s;
    padding: 7px;
    height: 34px;
    width: 100px;
    text-decoration: none;
    font-size: 10px;
    color: white;
    border-radius: 0 5px 5px 0;
    margin-top: 25px; 
    top: 0;
  }
  
  #workflowSidenav a:hover {
    left: 0;
  }
  
  #eventSidenav a {
    position: absolute;
    left: -50px;  
    transition: 0.3s;
    padding: 11px;
    height: 40px;
    width: 100px;
    text-decoration: none;
    font-size: 10px;
    color: white;
    border-radius: 0 5px 5px 0;
    margin-top: 30px; 
    top: 0;
}
 
#eventSidenav a:hover {
   left: 0;
}

#patientSidenav a {
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 10px;
    height: 36px;
    width: 100px;
    text-decoration: none;
    font-size: 10px;
    color: white;
    border-radius: 0 5px 5px 0;
    margin-top: 34px;
  }
  
  #patientSidenav a:hover {
    left: 0;
  }

  #SidenavModal a {
    position: absolute;
    left: -50px;
    transition: 0.3s;
    padding: 10px;
    height: 36px;
    width: 100px;
    text-decoration: none;
    font-size: 10px;
    color: white;
    border-radius: 0 5px 5px 0;
    margin-top: 139px;
  }
  
  #SidenavModal a:hover {
    left: 0;
  }

