.ss-main-header {
    width: 100%;
    margin-top: 0px;
    margin-left : 0px;
    margin-right : 0px;
    overflow: hidden

}
.ss-heading {
    margin-left: -70px;
    margin-top: -30px
}

.ss-main-card {
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 5px;
    width: 100%;
    height: 490px;
    overflow: hidden;
}
.ss-btn-submit {
    margin-left: 690px
}
.ss-btn-cancel {
    margin-left: 5px
}