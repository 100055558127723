@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
.navbar{
    position:fixed;
    top: 0;
    width:100%;
    color : '#33AEFF';
    background: var(--theme-background-color);
    /* background: linear-gradient(to right,rgb(66, 88, 208),  rgb(66, 88, 208)) */
    /* background: linear-gradient(-135deg, #c850c0, #4158d0); */
    /* background: linear-gradient(66deg, #348AA9, #4991f7); */
    /* background: linear-gradient(to right,rgb(57, 154, 211),  rgb(57, 154, 211)); */
}
.fb-icon {
    color :var(--theme-icon);
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: white;
}

.fa-icon-color{
  color :var(--theme-background-color);
}
.topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
}
.rounded-circle {
    border-radius: 50% !important;
}
img {
    vertical-align: middle;
    border-style: none;
}
.nav-link .img-profile {
    height: 2rem;
    width: 2rem;
}


.heading-appname {
     
    font-size: 24px;
    font-weight: bold;
    padding-left: 40px ;
    margin-right: 40px;
    color: white;
}
.search-container {
    padding-left: 60px;
    padding-right: 50px
    
    
}
#search-icon {
    display:'inline-block';
    margin-left:'70px';
    cursor: 'pointer';
    color : white;
}
.account-info {
    color: white;
    padding-right: 5px;
    margin-right: 0px
    
}
.user-display {
    
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-right: 10px
}

.user-icon {   
    color: white;
    margin-right: 5px;
    height: 45px;
   
}
 #search-input {
    font-family: FontAwesome;
}

@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) {
.navbar {width:100%}
    .heading-appname {
        font-size: 30px;
        font-weight: bold;
        padding-left: 5px ;
        color: white;
    }
    .search-container {
        padding-left: 5px
        
    }
    #search-icon {
        margin-left:'25px';
        cursor: 'pointer';
        color : white;
    }
    
} 
@media only screen 
and (min-width : 650px) 
and (max-width : 768px)
and (orientation: landscape) {
.navbar {width:100%}
    .heading-appname {
  
        font-size: 30px;
        font-weight: bold;
        padding-left: 5px ;
        color: white;
    }
    .search-container {
        margin-right: -100px
        
    }
    #search-icon {
        margin-left:'25px';
        cursor: 'pointer';
        color : white;
    }
    .account-info {
        font-family: 'Titilium Web' ;
        color: white;
        padding-right: 10px;
    }
    
}