

.main-wf {
    width : 100%;
    height: 100%;
    background: #DCDCDC	;
    position : relative !important; 
    margin-bottom: -10px
    
}
.wf-Card{
    margin: 4px;
}
.wf-div{
    cursor: pointer;
}
.block-list-workflow {
    /* padding: 20px 0; */
    max-width: 100%;
    max-height: 78px;
    height: 78px;
    background-color: #fff;
    /* border-bottom: 2px solid #cccccc */
}
.block-list-workflow {
    cursor: move;
}
.block-list-workflow :hover {
    background-color: silver;
}
.list-content {
    cursor: pointer;
    margin-top: 12px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    left: 0;

    /* margin-left: 100px;
    margin-top: -65px; */
    color: black
}
a {
    text-decoration: none;
}
.workflow-icon {
    /* margin-top: 15px; */
    margin-left: 15px;
    height: 45px;
    padding: 4px;
}
.wf-row {
 padding: 12px;  
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    
}
#workflow-maincard {
    max-height:450px;
    height:450px;
    width: 100%; 
    /* 1320px */
    overflow-y: auto;
    margin-left: 40px;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-right: 40px;
    font-weight: 200px
}

.heading {
    margin-left: 28px !important;
    margin-top: 26px !important;
}
.delete-icon-workflow {
    /* margin-left: 1286px;
    margin-top: -1000px;
    height: 136px; */
    /* margin-left: 1286px; 
        margin-left: 50px;
    */
    /* color: rgba(255, 0, 0, 0.664); */
    margin-top: 10px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    right: 0;
    margin-right: 40px;
    cursor: pointer;
}

._btn {
    height: 30px;
    font-size: 12px;
    float: right;
    background: rgb(57, 154, 211);
    color: white;
    margin-right: 10px;
    margin-top: 8px
    
}
.btn-back-gobal{

    height: 30px;
    font-size: 12px;
    margin-top: -10px;
    float: right;
    background: rgb(57, 154, 211);
    color: white;
    margin-right: 25px;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(57, 154, 211); 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(57, 154, 211); 
}


@media only screen 
and (min-width : 768px) 
and (max-width : 1024px) { 
    #workflow-maincard {
        max-height:450px;
        height:450px;
        width: 1120px;
        overflow-y: auto;
        margin-left: 40px;
        margin-bottom: 15px;
        margin-top: 10px;
        margin-right: 40px;
        font-weight: 200px
    }
    .block-list-workflow {
        /* padding: 20px 0; */
        max-width: 1000px;
        max-height: 100px;
        height: 100px;
        background-color: #fff;
        border-bottom: 2px solid #cccccc
    }


}