@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.main-header {
    width: 100%;
    margin-top: 50px;
    margin-left : 5px;
    margin-right : 5px;
    overflow: hidden

}
.lt-heading {
    margin-left: -80px
}

.main-card {
    margin-top : 5px;
    margin-left: 5px;
    margin-right: 15px;
    width: 100%;
    height: 500px;
}

.lt-input-search {
    background: white !important;
    width: 25px
    

}
.search-btn{
    margin-left: 45px;
    width: 250px

}
.btn-add-test-att {
    margin-top: 35px;
    margin-left: 500px;
    width: 150px
}
.content {
    margin-left: 100px
 
}
/* .form-group.required .col-form-label:after {
    content:"*";
    color:red;
  } */